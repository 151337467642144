<template>
    <a  href="#" class="dropdown-item"  v-for="trabajo in trabajos" :key="trabajo.id_aviso" >
        <div data-toggle="tooltip" data-placement="top" v-tooltip="trabajo.direccion_cliente" style="display:flex; justify-content:space-between; overflow-x:auto">
            <span @click.prevent="cargarExpediente(trabajo.id_servicio,trabajo)" style="width:100%"><i :class="trabajo.class" :style="trabajo.style" v-tooltip.bottom="trabajo.tipo"></i> {{trabajo.codigo_servicio}}</span>
            <span @click.prevent="cargarExpediente(trabajo.id_servicio,trabajo)" style="width:100%; text-align:center">{{ trabajo.nombre_tramitador }}</span>
            <span @click.prevent="cargarExpediente(trabajo.id_servicio,trabajo)" style="width:100%; text-align:center" class="float-right text-muted text-sm">{{trabajo.fecha_hora}}</span>
            <span v-if="extractContent(trabajo.nombre_especialidad,1)<=15" @click.prevent="cargarExpediente(trabajo.id_servicio,trabajo)" style="width:100%; text-align:end" class="float-right text-muted text-sm"> {{ extractContent(trabajo.nombre_especialidad) }}</span>
            <span v-if="extractContent(trabajo.nombre_especialidad,1)>15" @click.prevent="cargarExpediente(trabajo.id_servicio,trabajo)" style="width:100%; text-align:end" class="float-right text-muted text-sm"> {{ extractContent(trabajo.nombre_especialidad).slice(0,10)+"..." }}</span>
        </div>
    </a>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default({
    props:{
        trabajos: Array,
        todostrabajos: Boolean,
    },
    setup() {
        
    },
    methods: {
        cargarExpediente(id,trabajo){
            localStorage.setItem("tiposerv", 'norealizados');
            localStorage.setItem('nombreservicio' + id, trabajo.codigo_servicio);
            this.$router.push({
                name: 'Servicio',
                params: {
                    id:id
                }
            });
        },
           async eliminartrabajo(id_aviso) {
            //put pwgsapi/index.php/avisos/revisar-avisos
            const api = new PwgsApi();
            let ids = [];
            ids.push(id_aviso);
            let subidadatos = {tipo:'trabajos_no_revisados',ids_avisos:ids,estado:1,}
            await api.put('avisos/revisar-avisos', subidadatos);
            this.$parent.cargartrabajos();
        },
        extractContent(s,n) {
            var span = document.createElement('span');
            s
            if(n>0){
                //console.log('largaria',s?.length);
                return s?.length;
            }else{
                span.innerHTML = s;
                try{
                    return decodeURIComponent(escape(span.textContent)).replace(/<[^>]*>/g, '') || decodeURIComponent(escape(span.innerText)).replace(/<[^>]*>/g, '');
                }
                catch (error) {
                    console.log(error);
                    return s.replace(/<[^>]*>/g, '');
                }
            }
        },
        async checkeartodostrabajos() {
        //avisos/revisar-avisos
           const api = new PwgsApi();
           let ids = [];
           for (let i = 0; i < this.$props.trabajos.length; i++){
               ids.push(this.$props.trabajos[i].id_aviso);
           }
            let subidadatos = { tipo: 'trabajos_no_revisados', ids_avisos: ids, estado: 1 };
            await api.put('avisos/revisar-avisos', subidadatos);
            this.$parent.cargartrabajos(); 
        }
    },
    watch: {
        todostrabajos() {
            console.log("todostrabajos", this.$props.todostrabajos);
            if (this.$props.todostrabajos == true) {
                this.checkeartodostrabajos();
            }
        }
    }
})
</script>
