<template>

  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light" style="z-index: 999;">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <a class="navbar-brand" href="#">
        <img :src="logo"  height="36">
      </a>     
      <li v-if="this.$parent.novedades" class="nav-item d-none d-sm-inline-block" @mouseover="activarnovedades()">
        <div class="card card-info" style="margin:0px;"><div class="card-header" 
          style="border-bottom-left-radius:0.25rem;border-bottom-right-radius:0.25rem;padding:10px;font-size:11px;white-space:normal;word-wrap:break-word;overflow-wrap:break-word;display:block;max-width:300px;">
          <i class="fas fa-star mr-2"></i>
          <a v-if="this.$parent.novedades[0]?.titulo.length > 105" :title="this.$parent.novedades[0].titulo" target="_blank" :href="this.$parent.novedades[0].url" v-html="this.$parent.novedades[0].titulo.slice(0,102)+'...'"></a>
          <a v-else :title="this.$parent.novedades[0].titulo" target="_blank" :href="this.$parent.novedades[0].url" v-html="this.$parent.novedades[0].titulo"></a>
        </div></div>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" @click="iraescriorio()" class="nav-link">{{ $t('Escritorio') }}</a>
      </li>
      <li v-if="mostrarantiguo == 1" class="nav-item d-none d-sm-inline-block">
        <a href="#" @click="urlantigua()" class="nav-link" title="Versión anterior de la aplicación">PWGS5</a>
      </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <MenuSupAsignaciones :asignaciones="asignaciones" :numero_asignaciones="numero_asignaciones" />
      <MenuSupSolicitudes :solicitudes="solicitudes" :numero_solicitudes="numero_solicitudes" />
      <MenuSupTrabajosNoRevisados :trabajos="trabajos" :numero_trabajos="numero_trabajos" />
      <MenuSupAvisos />  
      <li class="nav-item">
        <a class="nav-link" title="Ampliar/Reducir el panel de:  Asignaciones/Solicitudes/TNR">
          <i @click="mostrar()" title="Ampliar/Reducir el panel de:  Asignaciones/Solicitudes/TNR" class="fas fa-arrows-alt-v"></i>
        </a>
      </li>
      <buscadorserviciosmenu></buscadorserviciosmenu>
      <!--<a title="Novedades" href="#" class="nav-link" @mouseover="activarnovedades()"><i class="fas fa-star"></i>&nbsp;<span class="badge badge-info">{{ longitudnovedades
          }}</span></a>-->
      <a title="WhatsApps" href="#" v-if="waActivado" class="nav-link" @click="abrirwhats()"><i class="fab fa-whatsapp"></i>&nbsp;<span class="badge badge-success">{{ whatsapps.length
          }}</span></a>
      <a title="Chats" href="#" class="nav-link" @click="abrirchats()"><i class="fab fa-weixin"></i>&nbsp;<span class="badge bg-purple">{{ chats
          }}</span></a>
      <a title="Alarmas de tramitadores" href="#" class="nav-link" @click="activaralarmas()"><i class="far fa-sticky-note"></i>&nbsp;<span class="badge badge-danger">{{ longitud
          }}</span></a>
      <MenuSupCorreos />
      <MenuSupWebphone v-if="hasExtension" :payload="payload" />
      <LocaleChanger />
      <MenuUsuario />
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
  <form id="TheForm" method="get" :action="'https://' + direccionglobal" target="TheWindow">
  </form>
</template>
<script>
import "admin-lte/plugins/flag-icon-css/css/flag-icons.css";
import LocaleChanger from './LocaleChanger.vue';
import MenuUsuario from './pages/menus/superior/usuario.vue';
import buscadorserviciosmenu from "./pages/menus/superior/BuscadorServicios/buscadorserviciosmenu.vue";
import MenuSupAsignaciones from './pages/menus/superior/asignaciones/AsignacionesMenu.vue';
import MenuSupSolicitudes from './pages/menus/superior/solicitudes/SolicitudesMenu.vue';
import MenuSupTrabajosNoRevisados from './pages/menus/superior/TrabajosNoRevisados/TrabajosNoRevisadosMenu.vue';
import MenuSupAvisos from './pages/menus/superior/avisos/AvisosMenu.vue';
import MenuSupCorreos from './pages/menus/superior/correos/CorreosMenu.vue';
import MenuSupWebphone from './pages/menus/superior/webphone/WebphoneMenu.vue';

import { PwgsApi } from "../services/PwgsApi";
export default ({
  props: ['longitud', 'longitudnovedades', 'trabajos', 'numero_trabajos', 'solicitudes', 'numero_solicitudes', 'asignaciones','numero_asignaciones', 'payload', 'chats', 'waActivado','whatsapps'],
   data() {
    return {
      direccionglobal: '',
      hasExtension: false,
      mostrarantiguo: 1,
    }
  },
  methods: {
    abrirwhats(){
      this.$router.push({
          name: 'WhatsApp',
      });
    },
    async comprobarantiguo() {
      const api = new PwgsApi();
      let aux = await api.get('parametros-pwgs/solo_quantum');
      if(aux.solo_quantum != 1){
        this.mostrarantiguo = 1;
      }else{
        this.mostrarantiguo = 0;
      }
      console.log("auxquantum", this.mostrarantiguo);
    },
    abrirchats(){
      this.$router.push({
          name: 'Chat',
      });
    },
    activaralarmas(){
      this.$parent.togglevisible();
    },
    activarnovedades(){
      this.$parent.togglevisiblenovedades();
    },
    mostrar() {
      this.$parent.mostrarextendido();
    },
    urlantigua() {
      //document.getElementById('usuario').value = localStorage.getItem('nombreusuario');
      //document.getElementById('contrasena').value = localStorage.getItem('pwd');
      window.open('', 'TheWindow');
      document.getElementById('TheForm').submit();
    },
    async cargarlogo() {
      const api = new PwgsApi();
      this.logo = await api.get('logo');
    },
    iraescriorio() {
      localStorage.setItem("tipobuscador", "defecto");

      this.$router.push({
        name: 'Escritorio'
      });

    }
    
  },
  setup() {   
    return;
  },
  components: {
    LocaleChanger,
    MenuUsuario,
    MenuSupAsignaciones,
    MenuSupSolicitudes,
    MenuSupTrabajosNoRevisados,
    MenuSupAvisos,
    MenuSupCorreos,
    buscadorserviciosmenu,
    MenuSupWebphone  
  },
  created() {
    this.cargarlogo();
    this.$parent.cargarnovedades();
  },
  mounted() {
    // Verificar si existe un valor en localStorage para 'extension'
    const extension = localStorage.getItem('extension');
    if (extension) {
      this.hasExtension = true; // Solo mostrar MenuSupWebphone si hay una extensión
    }
    this.direccionglobal = localStorage.getItem('direccion');
    this.comprobarantiguo();
  } 
})
</script>